import axios from "@/axios";
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/loader";
import LoaderFull from "@/components/loader-full";
import sectionLists from "@/pages/check-admin/check-dragable-section/components/sectionLists";
const addSectionModal = () => import("@/pages/check-admin/check-dragable-section/components/check-modals/add-section-modal");
// const addFieldModal = () => import("@/pages/check-admin/check-dragable-section/components/check-modals/add-field-modal");
const expandedPanel = () => import("@/pages/check-admin/check-dragable-section/components/expanded-panel");
const addBlockModal = () => import("@/pages/check-admin/check-dragable-section/components/check-modals/add-block-modal.vue");
import { cloneDeep } from "lodash";
import { getPackagesCheck } from "@/modules/checks/services.js";
import { formatPackageSections, fetchAllRoles, fetchReportVisibility } from "@/modules/field-editor/services.js";
import Button from "@/components/button";
const modalConfirm = () => import("@/components/modal-confirm");
import { uuid } from "vue-uuid";
import Empty from "@shared/empty";
import ModalContent from "@shared/modal-content";
import GapValidationModal from "@shared/components/modals/gap-validation-modal.vue";
import FieldSettingsModal from "@shared/components/modals/field-settings-modal.vue";
const AddFieldModal = () => import("@/pages/block-admin/components/addBlockAndFieldModal.vue");

const GapTimeLineForBlock = () => import('@shared/gapTimeLine')
import { checkPermission } from "@shared/utils/functions"

export default {
    name: "form-editor",
    components: {
        sectionLists,
        Loader,
        Button,
        addSectionModal,
        modalConfirm,
        // addFieldModal,
        expandedPanel,
        addBlockModal,
        Empty,
        ModalContent,
        GapValidationModal,
        FieldSettingsModal,
        AddFieldModal,
        GapTimeLineForBlock,
        LoaderFull
    },
    data() {
        return {
            tenantId: null,
            fieldLists: [],
            isLoading: false,
            packageId: null,
            packageData: null,
            sectionList: [],
            expandAllSection: false,
            fieldToAdd: {
                section: null,
                block: null,
                field: null,
                permisions: null,
                report_visibility: null,
            },
            activeSection: null,
            gapValidationPayload: {},
            loadingState: {
                addFields: false,
                getDependent: false,
                forms: false,
                dateType: false,
            },
            fetchingRoles: false,
            showSidePanel: false,
            activeFieldPanel: null,
            dependentsFields: [],
            hideAddFieldButtonInBlock: false,
            drag: false,
            loadingLists: false,
            blockSearchValue: "",
            blockLists: [],
            allReportsRoles: [],
            searchForm: "",
            assignedForms: [],
            activeGapValidation: {
                field: null,
                section: null,
            },
            activeFieldSettings: {
                field: null,
                section: null,
            },
            filterFiledValue: "",
            showBlockAddModal: false,
            listType: {
                type: "field",
                listModalTitle: "Add Field ",
                permissionModalTitle: "Field Settings",
            },
            currentAddedBlockGapField: null,
            currentAddedBlock: null,
            dateTypeFields: [],
            isGlobalLoader: {
                status: false,
                message: ''
            },
            queryData: null
        };
    },
    async mounted() {
        this.isLoading = true;
        if (!this.$store.getters.getTenantId) {
            await this.$store.dispatch("fetchTenantId");
        }
        this.packageId = this.$route.params.packageName;
        this.tenantId = this.$store.getters.getTenantId;
        this.queryData = this.$route.params?.action
        // this.fieldLists = await this.fetchFieldsList();
        await this.fetchForms();
        await this.fetchScreeningData();
        await this.fetchAvailableReportVisibility();
        await this.fetchAllAvailableRoles();
    },
    computed: {
        ...mapGetters(["getPackage", "getFormRows"]),
        isGapValidationField() {
            if (this.fieldToAdd.field?.meta) {
                const json_meta = JSON.parse(this.fieldToAdd.field.meta);
                return json_meta.use_in_gap_validation === true;
            }
        },
        filterBlockLists() {
            // return this.blockLists.filter((el) => el.name.toLowerCase().includes(this.blockSearchValue.toLowerCase()));
            return this.blockLists.filter((el) => el.name?.toLowerCase().includes(this.blockSearchValue.toLowerCase()) || el.label?.toLowerCase().includes(this.blockSearchValue.toLowerCase()));
        },
        filteredForms() {
            return this.getFormRows.filter((form) => form.name?.toLowerCase().includes(this.searchForm.toLowerCase()) && !this.assignedForms.some((assignform) => assignform.check_id === form.id));
        },
        has_forms() {
            return this.assignedForms?.length > 0;
        },
        filteredFieldList() {
            return this.fieldLists.filter((el) => el.name?.toLowerCase().includes(this.filterFiledValue.toLowerCase()));
        },
        isBlockGapValidation() {
            return this.currentAddedBlock.use_in_gap_validation || false
        },
        isUserPermission() {
            let permissions = {
                SHOW_ALL_ACTIONS: this.checkPermission("package.edit"),
                SHOW_FIELD_ADD_ACTIONS: this.checkPermission("field.read"),
                SHOW_BLOCK_ADD_ACTIONS: this.checkPermission("block.read"),
            };
            return permissions;
        },
    },
    methods: {
        checkPermission,
        ...mapActions(["fetchPackageData", "fetchFormRows"]),
        // Get packge data
        async fetchForms() {
            try {
                const { forms } = await getPackagesCheck(this.packageId);
                this.assignedForms = forms;
            } catch (error) {
                console.log(error, "[+]error while fetching forsm");
            }
        },
        async fetchScreeningData() {
            this.isLoading = true;
            if (!this.getPackage) {
                await this.fetchPackageData(this.packageId);
                this.packageData = this.getPackage;
            }
            this.packageData = this.getPackage;
            let sections = await this.getSectionLists();
            this.sectionList = formatPackageSections(sections);
            this.isLoading = false;
        },

        async fetchFieldsList() {
            let payload = {
                tenant_id: this.tenantId,
                req_offset: 0,
                req_limit: 1,
            };
            let url = `/fields/${this.tenantId}/all`;
            let { data } = await axios.get(url, payload);
            return data.fields;
        },

        // Fetching all Section list
        async getSectionLists() {
            this.isLoading = true;
            let url = `/package-section/${this.packageId}/section`;
            let { data } = await axios.get(url);
            this.isLoading = false;
            return data.data;
        },

        // Expand All Sections
        handleExpandAllSections(isSectionExpanded) {
            this.expandAllSection = !isSectionExpanded;
            this.sectionList = this.sectionList.map((el) => ({
                ...el,
                // expand all child blocks:
                fields: el.fields.map((field) => {
                    if (field.entity_type === "field") return field;
                    else if (field.entity_type === "block") {
                        return {
                            ...field,
                            expanded: this.expandAllSection,
                        };
                    }
                }),
                expanded: this.expandAllSection,
            }));
        },

        showSectionModal() {
            if (this.$route.params.action == 'view') {
                return
            }
            this.$refs["show-section-modal"].showAddSectionModal();
        },

        // Side Panel Settings
        handleClosePanel() {
            this.showSidePanel = false;
            this.activeFieldPanel = {
                field: null,
                section: null,
            };
        },

        onShowPanel(element, section) {
            if (element.is_cascaded) return;
            this.handleShowPanel(element, section);
        },

        async handleShowPanel(element, section) {
            if (!element.fields) {
                element.roles = element?.roles.map((role) => ({ ...role, select_all: role.role_mandatory && role.role_read && role.role_visible && role.role_write }));
                this.showSidePanel = true;
                this.activeFieldPanel = {
                    field: element,
                    section: section,
                    is_cascaded: element.is_cascaded,
                };
                if (element.is_cascaded) {
                    this.loadingState.getDependent = true;
                    try {
                        const { data } = await axios.get(`/package-section/${this.packageId}/section/${section.id}/field/${element.id}/dependents`);
                        this.dependentsFields = data.dependents;
                    } catch (error) {
                        console.log("error :>> ", error);
                    }
                    this.loadingState.getDependent = false;
                }
            }
        },

        // End Side panel Settings

        // Add New Section
        async addNewSection(sectionName) {
            let order = this.sectionList && this.sectionList.length > 0 ? this.sectionList[this.sectionList.length - 1].order + 1 : 200;
            if (!sectionName) return this.$toast.error("Section Name required");
            let url = `/package-section/${this.packageId}/section`;
            let payload = {
                section_name: sectionName,
                section_order: order,
            };
            try {
                let { data } = await axios.post(url, payload);
                let newSection = {
                    id: data.section_id,
                    name: sectionName,
                    expanded: true,
                    blocks: [],
                    fields: [],
                    order: order,
                };
                this.sectionList.push(newSection);
                this.$toast.success(data.message);
            } catch (error) {
                this.$toast.error(error.response.data.detail || "Failed to add new section");
            }
            this.closeSectionModal();
        },

        // End Add New Section

        // section Modal control
        closeSectionModal() {
            this.$refs["show-section-modal"].closeModal();
        },

        // update Section Label
        async updateSectionLabel(sectionData) {
            const url = `/package-section/${this.packageId}/section/${sectionData.id}`;
            let payload = {
                section_name: sectionData.name,
                section_order: sectionData.order,
            };
            try {
                const { data } = await axios.put(url, payload);
                this.$toast.success(data.message);
            } catch (error) {
                this.$toast.error(error.response.data.detail || "Failed to rename package section name!");
            }
        },

        // Update field Name

        // Delete Section

        async handleRemoveSection(section) {
            const promise = await this.$refs["confirm-popup"].show({
                title: "Are you sure?",
                message: "This section  will be deleted permanently. Please confirm to continue deleting this section.",
            });
            if (promise) {
                let url = `/package-section/${this.packageId}/section/${section.id}`;
                try {
                    let { data } = await axios.delete(url);
                    this.$toast.success(data.message);
                    let newList = this.sectionList.filter((ele) => ele.id !== section.id);
                    this.sectionList = newList;
                    this.$refs["confirm-popup"].close();
                } catch (error) {
                    this.$toast.error(error.response.data.detail || "Failed to remove section");
                    this.$refs["confirm-popup"].close();
                }
            }
            this.$refs["confirm-popup"].close();
        },

        // Delete Section

        // handle Gap Validation Data

        handleGapValidationPayload(payload) {
            this.gapValidationPayload = payload;
        },

        // End gapvalidation Dat

        // Change role permission

        PermissionChange(role) {
            console.log('FORM role', role)

            if (role.role_mandatory) {
                role.role_read = true;
                role.role_write = true;
                role.role_visible = true;
                role.select_all = true;
            } else if (role.role_write) {
                role.role_read = true;
                role.role_visible = true;
                role.select_all = false;
            } else if (role.role_read) {
                role.role_visible = true;
                role.select_all = false;
            }
        },

        // End change role permission

        async fetchAvailableReportVisibility() {
            this.allReportsRoles = await fetchReportVisibility();
        },

        // Fetching Roles
        async fetchAllAvailableRoles() {
            this.fetchingRoles = true;
            this.allRoles = await fetchAllRoles();
            this.fetchingRoles = false;
        },

        // End Fetching all Roles

        // Show field modal and fetch field lists and reports visibility
        async showfieldAddModal(section, block) {
            this.loadingState.addFields = true;
            this.filterFiledValue = "";
            // this.$refs["section-addfield-modal"].showAddFieldModal();
            this.showBlockAddModal = true;
            this.fieldToAdd.field = null;
            this.fieldLists = await this.fetchFieldsList();
            await this.fetchAvailableReportVisibility();
            let permisions =
                this.allRoles &&
                this.allRoles?.map((permision) => ({
                    ...permision,
                    title: permision.title,
                    role_read: false,
                    role_write: false,
                    role_visible: false,
                    role_mandatory: false,
                    select_all: false,
                }));
            this.fieldToAdd = {
                section: section,
                block: block ? block : null,
                field: null,
                permisions: permisions,
                all_reports_visibilty: this.allReportsRoles,
                report_visibility: null,
            };
            this.loadingState.addFields = false;
        },

        // end reorder section
        handleAddFieldChange(e) {
            console.log(e, "add field Event");
        },

        // End Field modal show

        // Close Field Modal

        closefieldAddModal() {
            this.showBlockAddModal = false
        },

        // End Close field modal

        // select all permissions

        handleSelectAllPermission(role) {
            let permisions = this.fieldToAdd.permisions;
            if (role.select_all) {
                permisions.map((el) => {
                    if (el.id === role.id) {
                        el.role_read = true;
                        el.role_write = true;
                        el.role_visible = true;
                        el.role_mandatory = true;
                    }
                });
            } else {
                permisions.map((el) => {
                    if (el.id === role.id) {
                        el.role_read = false;
                        el.role_write = false;
                        el.role_visible = false;
                        el.role_mandatory = false;
                    }
                });
            }
            this.fieldToAdd.permisions = permisions;
        },

        handleSelectAllFieldPermission(role) {
            this.activeFieldPanel.field.roles.map((el) => {
                // NOTE: FIX THIS

                if (el.id === role.id) {
                    if (role.select_all) {
                        el.role_read = true;
                        el.role_write = true;
                        el.role_visible = true;
                        el.role_mandatory = true;
                    } else {
                        el.role_read = false;
                        el.role_write = false;
                        el.role_visible = false;
                        el.role_mandatory = false;
                    }
                }
            });
        },

        // End select all permission

        // handle Add Field to Sections
        // async addFieldToSection() {
        //     if (!this.fieldToAdd.report_visibility) {
        //         this.$toast.error(`Please select a report visibility option before Adding new field`);
        //         return;
        //     }
        //     let { block, section, field, permisions, report_visibility } = this.fieldToAdd;
        //     let new_permisions = [];

        //     permisions?.map((permision) => {
        //         // NOTE: FIX THIS

        //         let payload_permision = {
        //             role_id: permision.id,
        //             role_read: permision.role_read,
        //             role_write: permision.role_write,
        //             role_visible: permision.role_visible,
        //             role_mandatory: permision.role_mandatory,
        //         };
        //         new_permisions.push(payload_permision);
        //     });

        //     let payload = {
        //         field_id: field.id,
        //         field_order: section.fields.length ? section.fields[section.fields.length - 1].field_order + 20 : 1,
        //         block_id: block ? block.id : null,
        //         // tenant_check_id: this.checkData.id,
        //         report_visibility,

        //         // field_details: {
        //         //     field_global_id: field.id,
        //         //     section_id: section.id,
        //         //     internal_use_only: false,
        //         //     block_id: block ? block.id : null,
        //         //     multi: false,
        //         //     max_field_or_block_count: 1,
        //         //     min_field_or_block_count: 1,
        //         //     is_top_level: false,
        //         //     report_visibility,
        //         // },
        //         permissions: new_permisions,
        //         use_in_gap_validation: this.isGapValidationField,
        //         is_cascaded: field.is_cascaded,
        //     };

        //     if (this.isGapValidationField) {
        //         const { history, valid_gap } = this.gapValidationPayload;
        //         const gapValid = {
        //             history: history || history == 0 ? history : 12,
        //             valid_gap: valid_gap || valid_gap == 0 ? valid_gap : 30,
        //         };
        //         payload = { ...payload, ...gapValid };
        //     }

        //     if (block && block.created) {
        //         this.addFieldToNewBlock(block, section, field, payload, permisions);
        //     } else {
        //         let url = `/package-section/${this.packageId}/section/${section.id}/field`;
        //         try {
        //             let { data } = await axios.post(url, payload);
        //             if (data.section_field_id) {
        //                 this.sectionList.map((sec) => {
        //                     if (sec.id === section.id) {
        //                         if (block) {
        //                             sec.fields.map((blockData) => {
        //                                 if (blockData.id === block.id) {
        //                                     // NOTE: FIX THIS
        //                                     blockData.fields.push({ ...payload, type: field.type, field_order: data.order || 200, report_visibility, block_id: block.id, entity_type: "field", id: data.section_field_id, label: field.label, name: field.name, placeholder: field.placeholder, roles: permisions.map((per) => ({ ...per, role_id: per.id })) });
        //                                 }
        //                             });
        //                         } else {
        //                             // NOTE: FIX THIS
        //                             sec.fields.push({
        //                                 ...payload,
        //                                 entity_type: "field",
        //                                 id: data.section_field_id,
        //                                 label: field.label,
        //                                 type: field.type,
        //                                 name: field.name,
        //                                 placeholder: field.placeholder,
        //                                 report_visibility,
        //                                 field_order: data.order || 200,
        //                                 roles: permisions.map((per) => ({ ...per, role_id: per.id })),
        //                             });
        //                         }
        //                     }
        //                 });
        //                 // await this.getCheckDetails()
        //                 this.$toast.success(data.message);
        //             }
        //             this.closefieldAddModal();
        //         } catch (error) {
        //             this.$toast.error(error.response.data.detail || "error while adding field to sections");
        //         }
        //     }
        // },

        // Add Field to new added Block
        addFieldToNewBlock(block, section, field, payload, permisions) {
            let fieldData = {
                field_id: payload.field_id,
                internal_use_only: false,
                max_field_or_block_count: payload.field_details.max_field_or_block_count,
                min_field_or_block_count: payload.field_details.min_field_or_block_count,
                is_top_level: payload.field_details.is_top_level,
                permissions: payload.permissions,
                report_visibility: payload.field_details.report_visibility,
                // "is_cascaded": payload.is_cascaded,
                use_in_gap_validation: this.isGapValidationField,
                use_in_case_creation: false,
            };

            // If a gap validation field is being added to block
            // it needs to have an additional parameter.
            if (this.isGapValidationField) {
                const { history, valid_gap } = this.gapValidationPayload;
                const gapValid = {
                    history: history || history == 0 ? history : 12,
                    valid_gap: valid_gap || valid_gap == 0 ? valid_gap : 30,
                };
                fieldData = { ...fieldData, ...gapValid };
            }

            this.sectionList.map((sec) => {
                if (sec.id === section.id) {
                    sec.fields.map((fie) => {
                        if (fie.id === block.id) {
                            fie.fields.push({
                                ...fieldData,
                                created_with_block: true,
                                block_id: block.id,
                                entity_type: "field",
                                id: uuid.v4(),
                                label: field.label,
                                name: field.name,
                                placeholder: field.placeholder,
                                roles: permisions.map((per) => ({ ...per, role_id: per.id })),
                            });
                        }
                    });
                }
            });
        },

        // End New block Field add

        // handle Delete Field form section and Block

        async handleDeleteField(field, section) {
            const promise = await this.$refs["confirm-popup"].show({
                title: "Are you sure?",
                message: "This field  will be deleted permanently. Please confirm to continue deleting this field.",
            });

            if (promise) {
                let url = `/package-section/${this.packageId}/section/${section.id}/field/${field.id}`;
                if (field.block_id && field.created_with_block) {
                    this.sectionList.map((sec) => {
                        if (sec.id === section.id) {
                            sec.fields.map((block) => {
                                if (block.id === field.block_id) {
                                    return (block.fields = block.fields.filter((el) => el.id !== field.id));
                                }
                            });
                        }
                    });
                } else {
                    try {
                        let { data } = await axios.delete(url);
                        if (field.block_id) {
                            this.sectionList.map((sec) => {
                                if (sec.id === section.id) {
                                    sec.fields.map((block) => {
                                        if (block.id === field.block_id) {
                                            return (block.fields = block.fields.filter((el) => el.id !== field.id));
                                        }
                                    });
                                }
                            });
                        } else {
                            this.sectionList.map((sec) => {
                                if (sec.id === section.id) {
                                    return (sec.fields = sec.fields.filter((el) => el.id !== field.id));
                                }
                            });
                        }
                        this.$toast.success(data.message);
                    } catch (error) {
                        this.$toast.error(error.response.data.detail || "Failed to delete field");
                    }
                }
            }
            this.$refs["confirm-popup"].close();
            this.handleClosePanel();
        },

        // End Delete Field from section and block

        // Update Field Label
        async updateFieldLabel(sectionData, checkData) {
            // console.log('checkData', checkData)
            checkData = checkData.element || checkData || sectionData.element || sectionData.field
            sectionData = sectionData.section || sectionData
            // console.log('checkData--------------------------', checkData)
            // console.log('sectionData---------------------', sectionData)
            if (!checkData) {
                return
            }
            this.handleControlGlobalLoader(true, 'Updating Field attributes...')
            console.log('checkData.label', checkData.label)
            console.log('his.oldFieldName', this.oldFieldName)
            if (this.oldFieldName !== checkData.label) {
                const url = `/package-section/${this.packageId}/section/${sectionData.id}/field/${checkData.id}`;
                let payload = {
                    field_order: checkData.field_order,
                    section_field_id: checkData.id,
                    label: checkData.label,
                    permissions: checkData.roles,
                    // multi: false,
                    // min_count: 0,
                    // max_count: 0,
                    report_visibility: checkData.report_visibility,
                    history: checkData.history,
                    valid_gap: checkData.valid_gap,
                    use_in_gap_validation: checkData.use_in_gap_validation,
                };
                try {
                    const { data } = await axios.put(url, payload);
                    if (checkData.use_in_gap_validation) {
                        const sectionList = cloneDeep(this.sectionList);
                        sectionList.forEach((section, ind1) => {
                            if (section.id === checkData.form_section_id) {
                                section.fields.forEach((field, ind2) => {
                                    if (field.id === checkData.block_id) {
                                        field.fields.forEach((_, ind3) => {
                                            if (_.id === checkData.id) {
                                                sectionList[ind1].fields[ind2].fields[ind3] = {
                                                    ..._,
                                                    history: checkData.history,
                                                    valid_gap: checkData.valid_gap,
                                                };
                                            }
                                        });
                                    } else {
                                        if (checkData.id === field.id) {
                                            sectionList[ind1].fields[ind2] = {
                                                ...field,
                                                history: checkData.history,
                                                valid_gap: checkData.valid_gap,
                                            };
                                        }
                                    }
                                });
                            }
                        });
                        this.sectionList = sectionList;
                        this.$refs.gapValidationModal.closeModal();
                    }
                    this.$toast.success(data.message);
                } catch (error) {
                    this.oldFieldName = "";
                    this.handleControlGlobalLoader(false)
                    this.$toast.error(error.response.data.detail || "Failed to rename field label!");
                    throw error;
                }
            }
            for (let item in checkData.roles) {
                console.log('item', item)
                if ((checkData.roles[item].title == 'Analyst' || checkData.roles[item].title == 'Client') && checkData.roles[item].role_write == false) {
                    try {
                        axios.patch(`screening-package/caseform/${checkData.id}`, {
                            state: false,
                        });
                    } catch (error) {
                        console.log(error);
                    }
                }
            }
            this.handleControlGlobalLoader(false)
        },

        fieldNameClick(fieldData) {
            this.oldFieldName = fieldData.label;
        },

        // END Update Field Label

        // Show Block Modal

        async showBlockModal(section) {
            this.activeSection = section;
            this.blockSearchValue = ""
            this.$refs["block-modal"].showBlockModal();
            await this.fetchBlockLists();
        },
        async fetchBlockLists() {
            this.loadingLists = true;
            try {
                let url = `/blocks`;
                let { data } = await axios.get(url);
                this.blockLists = data.data || [];
            } catch (error) {
                console.log(error, ">>>>>error");
            }
            this.loadingLists = false;
        },

        handleFilterResult(searchValue) {
            this.blockSearchValue = searchValue;
        },

        async addBlock(block) {
            this.currentAddedBlock = null
            this.currentAddedBlockGapField = null
            let section = this.activeSection;
            try {
                let url = `/package-section/${this.packageId}/section/${section.id}/block`;
                let payload = {
                    block_id: block.id,
                };
                let { data } = await axios.post(url, payload);
                let blockData = {
                    block_details: data,
                    block_multi: data.multi,
                    entity_type: "block",
                    expanded: true,
                    fields: data.fields,
                    name: data.name,
                    max_count:data.max_count || 1,
                    min_count:data.min_count || 1,
                    id: data.id,
                    from_check: false,
                    edited: false
                };
                this.sectionList.find((sec) => sec.id === section.id).fields.push(blockData);
                this.currentAddedBlock = blockData
                this.currentAddedBlockGapField = blockData?.fields.find(el => el.use_in_gap_validation)
                this.currentAddedBlockGapField = {
                    ...this.currentAddedBlockGapField, overlap_allowed: false,
                    relative: false,
                    start_date_field: null,
                    start_date_label: '',
                    end_date_field: null,
                    end_date_label: "",
                    till_present: false
                }
                // this.sectionList = this.sectionList.map(el => {
                //     if(el.id === section.id){
                //         el.fields.push(blockData)
                //     }
                // })
                this.$toast.success(data.message || "Block added to package");
                this.$refs["block-modal"].closeModal();
                this.showGapTimeLineModal()
            } catch (error) {
                this.$toast.error(error.response.data.detail || "Failed to add block");
            }

            // let url = `/tenant-check/${check_id}/section/${section.id}/block`;
            // let payload = {
            //     fields: [],
            //     id: uuid.v4(),
            //     expanded: true,
            //     entity_type: "block",
            //     created: true,
            //     edited: false,
            //     name: blockName,
            //     block_multi: false,
            //     min_count: 1,
            //     max_count: 1,
            // };
            // this.hideAddFieldButtonInBlock = true;
            // this.sectionList.find((sec) => sec.id === section.id).fields.push(payload);
            // this.$toast.success("Block Added");
            this.$refs["block-modal"].closeModal();
        },

        // Save Block
        async saveBlockFields(section, element) {
            let url = `/package-section/${this.packageId}/section/${section.id}/block`;
            let payload = {
                name: element.name,
                multi: element.block_multi,
                field_list: [],
                max_count: parseInt(element.max_count),
                min_count: parseInt(element.min_count),
            };

            try {
                let { data } = await axios.post(url, payload);
                this.hideAddFieldButtonInBlock = false;
                element.created = false;
                element.id = data.block_id;
                this.$toast.success(data.message);
            } catch (error) {
                this.$toast.error(error.response.data.detail || "failed to create block");
            }
        },

        // Collapse block
        handleCollapseBlock(section, block) {
            console.log('section', section)
            // section = section?.section || section
            console.log('section, block 1', section, block)
            this.sectionList.find((sec) => sec.id === section.id).fields.find((bl) => bl.id === block.id).expanded = !block.expanded;
        },

        // Remove Block

        async handleRemoveBlock(section, block) {
            const promise = await this.$refs["confirm-popup"].show({
                title: "Are you sure?",
                message: "This Block  will be deleted permanently. Please confirm to continue deleting this Block.",
            });
            if (promise) {
                let url = `/package-section/${this.packageId}/section/${section.id}/block/${block.id}`;
                if (block.created) {
                    this.sectionList.map((sec) => {
                        if (sec.id === section.id) {
                            let fields = sec.fields.filter((el) => el.id !== block.id);
                            sec = sec.fields = fields;
                        }
                    });
                } else {
                    try {
                        let { data } = await axios.delete(url);

                        this.sectionList.map((sec) => {
                            if (sec.id === section.id) {
                                let fields = sec.fields.filter((el) => el.id !== block.id);
                                sec = sec.fields = fields;
                            }
                        });
                        this.$toast.success(data.message || "Block Deleted");
                    } catch (error) {
                        this.$toast.error(error.response.data.detail || "failed to delete block");
                    }
                }
            }
            this.$refs["confirm-popup"].close();
        },

        // End Remove Block

        // Update block

        async handleUpdateBlock(section, block) {
            console.log('section, block', section, block)
            let url = `/package-section/${this.packageId}/section/${section.id}/block/${block.id}`;
            let payload = {
                name: block.name,
                label: block.label,
                multi: block.block_multi,
                min_count: block.min_count,
                block_title: block.block_details.block_title,
                max_count: block.max_count,
            };
            try {
                let { data } = await axios.put(url, payload);
                block.edited = false;
                this.$toast.success(data.message || "Block Updated");
            } catch (error) {
                this.$toast.error(error.response.data.detail || "failed to update block details");
            }
        },

        // end update Block

        // Update Dependendta Field label
        async updateDependentsFieldLabel(field) {
            const url = `/package-section/${this.packageId}/section/${field.form_section_id}/field/${field.id}`;
            let payload = {
                section_field_id: field.id,
                label: field.name,
                permissions: field.permissions,
                report_visibility: field.report_visibility,
                field_order: field.field_order,
            };
            try {
                const { data } = await axios.put(url, payload);
                this.$toast.success(data.message);
            } catch (error) {
                this.$toast.error(error.response.data.detail || "Failed to rename field label!");
            }
        },

        // Reordering section and Fields

        // reorder section
        async hanldeSectionReorder(e) {
            let section_list = [];
            this.sectionList.forEach((el, index) => section_list.push({ section_id: el.id, order: index + 1 }));
            if (e.moved) {
                const url = `/package-section/${this.packageId}/section/reorder`;
                let payload = {
                    section_list: section_list,
                };
                try {
                    const { data } = await axios.post(url, payload);
                    this.$toast.success(data.message);
                } catch (error) {
                    this.$toast.error(error.response.data.detail || "Failed to reorder section!");
                }
                // this.$toast.success("Section Reordered");
            }
        },

        async changeCaseCreation(payload) {

            try {
                const result = await axios.patch(`screening-package/caseform/${payload.id}`, {
                    state: payload.use_in_case_creation || false,
                });
                if (result) {
                    this.$toast.success(payload.use_in_case_creation ? `Using in case creation` : `Removed from case creation`);
                }
            } catch (error) {
                this.$toast.error(error.response.data.detail || `Something went wrong`);
                const setCreationVal = (fld) => {
                    return fld.id === payload.id ? { ...fld, use_in_case_creation: !payload.use_in_case_creation || false } : fld
                }
                this.sectionList = this.sectionList.map(sec => {
                    return {
                        ...sec, fields: sec.fields.map(fld => {
                            if (fld.entity_type === "field") {
                                return setCreationVal(fld)
                            } else if (fld.entity_type === "block") {
                                // for field type returning use_in_case_creation as false 
                                return { ...fld, fields: fld.fields.map(el => setCreationVal(el)) }
                            }
                        })
                    }
                })
            }
        },
        async changeblockVisibile(payload) {
            try {
                const result = await axios.patch(`screening-package/block_visibility/${payload.id}`, {
                    block_visibility: payload.block_visibility || false,
                });
                if (result) {
                    this.$toast.success(payload.block_visibility ? `Using in block_visibility` : `Removed from block_visibility`);
                }
            } catch (error) {
                this.$toast.error(error.response.data.detail || `Something went wrong`);
                const setblockVisibleVal = (fld) => {
                    return fld.id === payload.id ? { ...fld, block_visibility: !payload.block_visibility || false } : fld
                }
                this.sectionList = this.sectionList.map(sec => {
                    return {
                        ...sec, fields: sec.fields.map(fld => {
                            if (fld.entity_type === "field") {
                                return setblockVisibleVal(fld)
                            } else if (fld.entity_type === "block") {
                                // for field type returning block_visibility as false 
                                return { ...fld, fields: fld.fields.map(el => setblockVisibleVal(el)) }
                            }
                        })
                    }
                })
            }
        },
        // Field Reorder
        async handleFieldChange(e) {
            debugger; // eslint-disable-line no-debugger
            let field_lists = [];
            this.sectionList.forEach((section) =>
                section.fields.forEach((field) => {
                    if (field.entity_type === "block") {
                        field.fields.forEach((blockField) => field_lists.push({ section_field_id: blockField.id, section_id: section.id, order: field_lists.length + 1 }));
                    } else {
                        field_lists.push({ section_field_id: field.id, section_id: section.id, order: field_lists.length + 1 });
                    }
                })
            );
            if (e.added || e.moved) {
                const url = `/package-section/${this.packageId}/field/reorder`;
                let payload = {
                    field_list: field_lists,
                };
                try {
                    const { data } = await axios.post(url, payload);
                    if(e.added)
                    {
                        this.$toast.success(e.added.element.entity_type === 'block' ?  'Block reordered'||data.message : 'Field reordered' || data.message);
                    }
                    if(e.moved){
                        this.$toast.success(e.moved.element.entity_type === 'block' ?  'Block reordered'||data.message : 'Field reordered' || data.message);
                    }
                    if(e.removed){
                        this.$toast.success(e.removed.element.entity_type === 'block' ?  'Block reordered'||data.message : 'Field reordered' || data.message);
                    }
                } catch (error) {
                    this.$toast.error(error?.response?.data?.detail || "Failed to reorder!");
                }
                // this.$toast.success("field added")
            }
        },
        async onAddForm() {
            if (this.$route.params.action == 'view') {
                return
            }
            this.$refs["add-form-modal"].showModal();
            this.loadingState.forms = true;
            await this.fetchFormRows();
            this.loadingState.forms = false;
        },
        async addFormToPack(form) {
            this.loadingState.forms = true;
            try {
                const { data } = await axios.post(`/tenant-form/map-form-package/${this.packageId}/${form.id}`);
                await this.fetchScreeningData();
                await this.fetchForms();
                this.$toast.success(data.message || "Form added to package");
            } catch (error) {
                this.$toast.error(error.response.data.detail || "Failed to add form to package!");
            }
            this.loadingState.forms = false;
        },
        async removeCheckFromPackage(check) {
            if (this.$route.params.action == 'view') {
                return
            }
            const promise = await this.$refs["confirm-popup"].show({
                title: "Are you sure?",
                message: "This Form  will be deleted permanently. Please confirm to continue deleting this form.",
            });
            if (promise) {
                const package_id = this.$route.params.packageName;
                const url = `/screening-package/${package_id}/check/${check.check_id}`;

                try {
                    const data = await axios.delete(url);
                    await this.fetchScreeningData();
                    await this.fetchForms();
                    this.$toast.success(data.message || "Form removed from package");
                } catch (error) {
                    this.$toast.error(error.response.data.detail || "could not remove form from package!");
                    this.$toast.error("could not remove form from package.");
                }
            }
            this.$refs["confirm-popup"].close();
            // this.checks.push(check)
        },
        showGapValidationModal(payload = null) {
            if (!payload) {
                this.activeGapValidation = {
                    field: null,
                    section: null,
                };
                return;
            }
            const { field, section } = payload;
            this.activeGapValidation = {
                field,
                section,
            };
            this.activeSection = section
            this.currentAddedBlockGapField = field
            this.currentAddedBlock = field
            this.showGapTimeLineModal()
            // this.$refs.gapValidationModal.showModal();
        },
        onBeforeClosedBlockList() {
            this.blockSearchValue = "";
        },
        onBeforeOpenSearch() {
            this.searchForm = "";
        },
        showFieldSettingsModal(payload = null) {
            if (!payload) {
                this.activeFieldSettings = {
                    field: null,
                    section: null,
                };
                return;
            }
            const { field, section } = payload;
            field.roles = field?.roles.map((role) => ({ ...role, select_all: role.role_mandatory && role.role_read && role.role_visible && role.role_write }));
            this.activeFieldSettings = {
                field,
                section: section,
                is_cascaded: field.is_cascaded,
            };
            this.$refs.fieldSettingsModal.showModal();
        },
        async getOptions(field_id) {
            const result = await axios.get(`/package-section/${this.packageId}/field/${field_id}/options`);
            return result;
        },
        async getDependents(field_id, option_value) {
            const result = await axios.get(`/package-section/field/${field_id}/options/${option_value}/dependents`);
            return result;
        },
        onChangeFilterResult(event) {
            this.filterFiledValue = event;
        },
        async onAddField({ payload, field }) {
            const { roles, report_visibility, use_in_gap_validation } = payload;
            const { label, id, is_cascaded } = field;
            const { section } = this.fieldToAdd;
            if (!report_visibility) {
                this.$toast.error(`Please select a report visibility option before Adding new field`);
                return;
            }
            let payloadBody = {
                field_id: id,
                field_order: section.fields.length ? section.fields[section.fields.length - 1].field_order + 20 : 1,
                block_id: null,
                report_visibility,
                permissions: roles,
                is_cascaded,
                use_in_case_creation: false,
            };
            if (use_in_gap_validation) {
                const { history, valid_gap } = payload;
                const gapValid = {
                    history: history || history == 0 ? history : 12,
                    use_in_gap_validation: true,
                    valid_gap: valid_gap || valid_gap == 0 ? valid_gap : 30,
                };
                payloadBody = { ...payloadBody, ...gapValid };
            }
            let url = `/package-section/${this.packageId}/section/${section.id}/field`;
            try {
                let { data } = await axios.post(url, payloadBody);
                if (data.section_field_id) {
                    this.sectionList.map((sec) => {
                        if (sec.id === section.id) {
                            sec.fields.push({
                                ...payloadBody,
                                entity_type: "field",
                                id: data.section_field_id,
                                label: label,
                                type: field.type,
                                name: field.name,
                                placeholder: field.placeholder,
                                report_visibility,
                                field_order: data.order || 200,
                                roles: roles,
                                ui_label: field.ui_label,
                                is_custom: field.is_custom,
                                is_restricted: field.is_restricted
                            });
                        }
                    });
                    // await this.getCheckDetails()
                    this.$toast.success(data.message);
                }
                this.closefieldAddModal();
            } catch (error) {
                this.$toast.error(error.response.data.detail || "error while adding field to sections");
            }


        },

        closeGapTimeLineModal() {
            this.$modal.hide('gap-time-line-modal');
        },
        async showGapTimeLineModal() {
            await this.getDateTypeFields()
            let { start_date_field, end_date_field } = this.currentAddedBlockGapField
            if (start_date_field || end_date_field) {
                this.currentAddedBlockGapField = { ...this.currentAddedBlockGapField, start_date_field: this.dateTypeFields.find(el => el.id === start_date_field), end_date_field: this.dateTypeFields.find(el => el.id === end_date_field) }
            }
            this.$nextTick(() => {
                this.isBlockGapValidation && this.$modal.show('gap-time-line-modal');
                // this.isBlockGapValidation && this.dateTypeFields && this.dateTypeFields.length > 0 && this.$modal.show('gap-time-line-modal');

            });

        },
        async getDateTypeFields() {
            this.loadingState.dateType = true
            const { data } = await axios.get(`package-section/${this.packageId}/fields/dates`);
            this.dateTypeFields = data.data;
            this.loadingState.dateType = false

        },
        handleResetGapTimelineFields() {
            this.currentAddedBlockGapField = {
                ...this.currentAddedBlockGapField,
                relative: false,
                valid_gap: null,
                history: null,
                overlap_allowed: false,
                start_date_field: null,
                start_date_label: "",
                end_date_field: null,
                end_date_label: "",
                till_present: false,
            }
        },
        async handleUpdateBlockFieldGapTimeLine(copyCurrentBlockField){
            // let { id, roles, history,field_order, valid_gap, block_id, label, report_visibility, overlap_allowed,relative, start_date_field , start_date_label, end_date_field, end_date_label, till_present } = this.currentAddedBlockGapField
            // let payload = {
            //     section_field_id: id,
            //     permissions:roles,
            //     history, 
            //     valid_gap, 
            //     label, 
            //     report_visibility, 
            //     overlap_allowed,
            //     relative, 
            //     start_date_field:start_date_field && start_date_field.id || null , 
            //     start_date_label, 
            //     end_date_field:end_date_field && end_date_field.id || null, 
            //     end_date_label, 
            //     till_present,
            //     field_order
            // }
            let {id, roles, history, field_order, valid_gap, block_id, label, report_visibility, overlap_allowed,relative, start_date_field , start_date_label, end_date_field, end_date_label, till_present } = copyCurrentBlockField;
            let payload = {
                section_field_id: id,
                permissions: roles,
                label,
                report_visibility,
                overlap_allowed: overlap_allowed || false,
                valid_gap,
                relative: relative || false,
                field_order,
            };
            if (relative) {
                if (till_present) {
                    payload = {
                        ...payload,
                        start_date_field: (start_date_field && start_date_field.id) || null,
                        start_date_label,
                        end_date_field: null,
                        end_date_label: null,
                        till_present: till_present || false,
                        history: null,
                    };
                } else {
                    payload = {
                        ...payload,
                        start_date_field: (start_date_field && start_date_field.id) || null,
                        start_date_label,
                        end_date_field: (end_date_field && end_date_field.id) || null,
                        end_date_label,
                        till_present: till_present || false,
                        history: null,
                    };
                }
            } else {
                payload = {
                    ...payload,
                    history,
                    start_date_field: null,
                    start_date_label: null,
                    end_date_field: null,
                    end_date_label: null,
                    till_present: false,
                };
                        }
            if(payload.history > 350) {
                this.$toast.error("History(months) cannot be greater than 350")
            }
            else if(payload.history === 0 && payload.valid_gap>30) {
                this.$toast.error("Valid gap(days) cannot be greater than History(months)");
            }
            else if(payload.valid_gap > payload.history*30 && payload.history > 0){
                this.$toast.error("Valid gap(days) cannot be greater than History(months)")
            }
            else{
            try {
                const url = `/package-section/${this.packageId}/section/${this.activeSection.id}/field/${id}`;
                const { data } = await axios.put(url, payload);
                this.$toast.success(data.message);
                const sectionList = cloneDeep(this.sectionList);
                sectionList.forEach((section, ind1) => {
                    if (section.id === this.activeSection.id) {
                        section.fields.forEach((field, ind2) => {
                            if (field.id === block_id) {
                                field.fields.forEach((_, ind3) => {
                                    if (_.id === id) {
                                        sectionList[ind1].fields[ind2].fields[ind3] = {
                                            ..._,
                                            ...payload,
                                        };
                                    }
                                });
                            }
                        });
                    }
                });
                this.sectionList = sectionList
                this.closeGapTimeLineModal()
            } catch (error) {
                this.$toast.error(error?.response?.data?.detail[0].msg || "Failed to update field!");
            }
        }
        },
        updateOption({ option, key }) {
            this.currentAddedBlockGapField = { ...this.currentAddedBlockGapField, [key]: option };
        },
        updateTillPreset({input,startDateLabel}) {
            if(input === 'till_present'){
                this.currentAddedBlockGapField = { ...this.currentAddedBlockGapField, till_present: true, end_date_field:null, end_date_label:"",start_date_label: startDateLabel};
            }else{
                this.currentAddedBlockGapField = { ...this.currentAddedBlockGapField, till_present: false, start_date_label: startDateLabel };
            }
        },
        updateActiveTab(event) {
            let relative_status = event === 'relative' ? true : false
            this.currentAddedBlockGapField = { ...this.currentAddedBlockGapField, relative: relative_status };
        },
        handleControlGlobalLoader(status, message) {
            this.isGlobalLoader = {
                status,
                message: message || 'Loading...'
            }
        }
    }
};
